import Heading from '../Heading';
import About from './About/About';
import Contact from './Contact/Contact';
import Experience from './Experience/Experience';
import Projects from './Projects/Projects';

export default function Content({content}){

    
  return (
    <div className='col-12 col-md-9 col-lg-6 offset-0 offset-md-3 offset-lg-6 py-5 fs-5 px-1 px-md-3 px-lg-5'>
      <div className='row d-md-none justify-content-center'>
        <div className='col-6 pt-1 pb-5'>
          <Heading/>
        </div>
      </div>
      <About data={content.about}/>
      <Experience projects={content.experience}/>
      <Projects projects={content.projects}/>
      <Contact />
    </div>
  );
};