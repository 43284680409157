import { useState } from "react";
import {sendRequest} from "../../../utils/EmailService";
import './contact.css';


export default function Contact(){

  const [formData, setFormData] = useState({
    name: '', email: '', message: ''
  }); 
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [errors, setErrors] = useState([]);

  function handleChange(event) {
    setFormData(prevFormData => {
      return {
        ...prevFormData,
        [event.target.name]: event.target.value
      }
    });
  };

  function handleSubmit(event){
    event.preventDefault();
    let formErrors = [];
    if (formData.message.length === 0){
      formErrors.push('messageEmpty')
    }
    if (formData.message.length > 600){
      formErrors.push('messageOverSize')
    }
    if (formData.name.length > 50){
      formErrors.push('nameOverSize');
    }
    if (formData.email.length > 50){
      formErrors.push('emailOverSize');
    }
    if (formData.email.length > 0 && !validateEmail(formData.email)){
      formErrors.push('emailInvalid');
    }
    // todo check for email correctness
    if (formErrors.length > 0){
      setErrors(formErrors);
    }
    else {
      setFormSubmitted(true);
      sendRequest(formData);
    }
  }

  function resetForm(){
    setFormData({name: '', email: '', message: ''});
    setFormSubmitted(false);
  }

  function validateEmail (email){
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };
  
  return (
    <section className='p-1 p-md-3 p-lg-5' id='contact'>
      <p>
        Contact me on <a className='text-decoration-none' href='https://www.linkedin.com/in/isaakkrut/'><u>LinkedIn</u></a> or
        by <a className='text-decoration-none' href='mailto:isaakkrut@outlook.com'><u>Email</u></a>
      </p>
      {!formSubmitted && 
        <div>
          <p>Or share your message below</p>
          <form onSubmit={handleSubmit}>
            <fieldset>
              <legend>From</legend>
              <div className='ms-4 mb-3'>
                <label htmlFor='name' className='form-label'>Name(optional)</label>
                <input type='text' className='form-control' name='name' maxLength='50' id='name'
                onChange={handleChange} value={formData.name} />
                { errors.includes('nameOverSize') &&
                  <p className='error'>Name cannot be over 50 characters</p>
                }
              </div>
              <div className='ms-4 mb-3'>
                <label htmlFor='email' className='form-label'>Reply-To email address(optional)</label>
                <input type='email' className='form-control' name='email' aria-describedby='emailHelp' id='email'
                      maxLength='50'  onChange={handleChange} value={formData.email} />
                { errors.includes('emailOverSize') &&
                  <p className='error'>Email cannot be over 50 characters</p>
                }
                { errors.includes('emailInvalid') &&
                  <p className='error'>Invalid email address</p>
                }
              </div>
            </fieldset>
            <fieldset>
              <legend>Message</legend>
              <div className='mb-3 form-check'>
                <textarea className='form-control' placeholder='Leave your message here' name='message' id='message'
                          style={{height: '300px'}} maxLength='600' onChange={handleChange} value={formData.message} />
                { errors.includes('messageEmpty') && 
                  <p className='error'>Message cannot be empty</p>
                }
                { errors.includes('messageOverSize') && 
                  <p className='error'>Message cannot be over 600 characters</p>
                }
              </div>
            </fieldset>
            <button type='submit' className='btn btn-primary' style={{'borderRadius': '0.25rem'}} id='sendMessage'>Send</button>
          </form>
        </div>
      }
      {formSubmitted && 
        <div>
          <p id='messageSent'>Message is sent. Expect a reply soon!</p>
          <button onClick={resetForm} className='btn btn-primary' style={{'borderRadius': '0.25rem'}}>Send another message</button>
        </div>
      }
    </section>
  )
}