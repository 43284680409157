import {useState} from 'react';

import './sidebar.css';

import Navbar from './Navbar/Navbar';
import Links from './Links/Links';
import Heading from '../Heading';


export default function Sidebar() {

  const navbarElements = ['about', 'experience', 'projects', 'contact'];
  const [selectedElement, setSelectedElement] = useState();

  function selectElement(element){
    setSelectedElement(element);
  };

  return (
    <div className='row'>
    <div className='col-md-3 col-lg-6 position-fixed m-md-3 m-lg-5 p-md-3 p-lg-5 d-none d-md-block'>
      <div className='offset-0 offset-lg-2'>
          <div className='row justify-content-start m-3 p-3'>
            <div className='col-12'>
              <Heading/>
            </div>
            </div>
            <div className='row'>
              <div className='col-12'>
                <Navbar 
                  elements= {navbarElements}
                  selectedElement= {selectedElement}
                  selectElement= {selectElement}
                />
              </div>
            </div>
            <div className='row'>
              <div className='col-12'>
                <Links />
              </div>
          </div>
        </div>
      </div>
    </div>
  );
};
