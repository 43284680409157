import './App.css';
import Content from './components/Content/Content';
import Sidebar from './components/Sidebar/Sidebar';
import content from './data/content.json';

function App() {

  window.addEventListener('scroll', (event) =>{
    const sections = document.querySelectorAll("section");
    const navLi = document.querySelectorAll(".nav-item");
    let current = "";
    sections.forEach((section) => {
      const sectionTop = section.offsetTop;
      const sectionHeight = section.clientHeight;
      if ( window.scrollY >= sectionTop - sectionHeight / 3) {
        current = section.getAttribute("id");
      }
    });
    navLi.forEach((li) => {
      li.classList.remove("text-selected");
      if (li.classList.contains(current)) {
        li.classList.add("text-selected");
      }
    });
  });

  return (
    <div className='App'>
      <Sidebar />
      <Content content={content}/>
    </div>
  );
}

export default App;
