
export default function Experience({projects}){

  let projectElements = projects.map(project => {
    let projectSkills = project.skills.map(skill => 
    <li className='mr-1.5 mt-2 list-inline-item' key={skill}><div className='tag fs-6 fw-semibold px-2 py-1'>{skill}</div></li>
    );

    return (
        <li className='list-inline-item' key={project.title}>
          <div className='card border-0 shadow'>
            <div className='card-header'>
              <h5 className='card-title text-selected font-weight-bold'>{project.title}</h5>
              {project.dates && <div><span className='fs-6'>{project.dates}</span><br></br></div>}
              {project.location && <span className='fs-6'>{project.location}</span>}
            </div>
            <div className='card-body'>
              <p className='card-text'>{project.summary}</p>
              <ul className='mt-2 flex flex-wrap list-inline' aria-label='Technologies used'>
                {projectSkills}
              </ul>
            </div>
          </div>
        </li>
        )
  });

  return (
    <section className='p-1 p-md-3 p-lg-5' id='experience'>
      <ol className='list-inline'>
        {projectElements}
      </ol>
    </section>
  )
};