// eslint-disable-next-line
import {useState} from 'react';

export default function Hoverable(props) {
  const [isMouseInside, setIsMouseInside] = useState(false);

  function onMouseEnter () {
    setIsMouseInside(true);
  }

  function onMouseLeave () {
    setIsMouseInside(false);
  }

  return props.children(isMouseInside, onMouseEnter, onMouseLeave);
}