
export default function Navbar({elements, selectedElement, selectElement}) {

    let navbarElements = elements.map(element=> 
        <li className={'mr-1.5 mt-2 nav-item hover-highlight ' + element + (element === selectedElement ? ' text-selected' : '')}
            onClick={()=>selectElement(element)} key={element}>
            <a href={'#' + element} className='text-decoration-none text-reset'>
                <h5 className='nav-item-text'>{element.charAt(0).toUpperCase() + element.slice(1)}</h5>
            </a>
        </li>
        );

    return (
        <ul>
            {navbarElements}
        </ul>
    );
};